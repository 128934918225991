<template>
    <div>
        <span v-if="label" class="label">{{ label }}</span>
        <v-row no-gutters>
            <v-col cols="4">
                <kimsa-select
                    :solo="solo"
                    :outlined="outlined"
                    :flat="flat"
                    group
                    :value="items[0] || null"
                    :icon="itemSelected && itemSelected.icon || null"
                    :dense="dense"
                    :items="items"
                    item-text="name"
                    item-value="value"
                    return-object
                    @change="itemSelected = $event"
                >
                </kimsa-select>
            </v-col>
            <v-col cols="8">
                <v-text-field
                    :autofocus="autofocus"
                    :autocomplete="autocomplete"
                    :color="color"
                    :value="defaultValue"
                    @input="onChange"
                    @click:clear="model = ''"
                    @click="$emit('click')"
                    @blur="emitBlur"
                    @keydown.enter="emitEnter"
                    @keypress="isNumber($event)"
                    :placeholder="placeholder"
                    :rules="rules"
                    :prefix="prefix"
                    :clearable="clearable"
                    :inputmode="inputMode"
                    :hide-details="!showDetails"
                    :solo="solo"
                    :outlined="outlined"
                    :flat="flat"
                    :dense="dense"
                    :prepend-inner-icon="icon"
                    :append-icon="appendIcon"
                    @click:append="$emit('click:append')"
                    :readonly="readonly"
                    :loading="loading"
                    :label="inputLabel"
                    class="group"
                >
                </v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
export default {
    name: "KimsaTextFieldWithSelect",
    components: {KimsaSelect},
    props: {
        autofocus: {
            type: Boolean,
            default: false
        },
        inputMode: {
            type: String,
            default: 'text'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        prefix: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: '',
        },
        inputLabel: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: '',
        },
        elevation: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
        appendIcon: {
            type: String,
            default: '',
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: '',
        },
        disableDots: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            model: '',
            itemSelected: null,
        }
    },
    computed: {
        defaultValue() {
            const vm = this
            if (vm.inputMode === 'numeric' && !vm.disableDots) {
                return vm.$options.filters.number(vm.model)
            }
            return vm.model
        },
        form(vm = this) {
            return {
                selected: vm.itemSelected && vm.itemSelected.value || null,
                model: vm.model || '',
            }
        },
    },
    methods: {
        onChange(value) {
            const vm = this
            if (!value) return vm.model = ''

            if (vm.inputMode === 'numeric') {
                return vm.model = Number(value.toString().replace(/\./g, ''))
            }

            return vm.model = value
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        },
        reset() {
            const vm = this
            vm.model = ''
        },
        isNumber(event) {
            const vm = this
            if (vm.inputMode !== 'numeric') return
            const charCode = event.keyCode
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault()
            } else {
                return true
            }
        },
        emitBlur() {
            this.$emit('blur')
        },
        emitEnter() {
            this.$emit('keydown:enter')
        }
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model() {
            this.$emit('change', this.form)
        },
        itemSelected() {
            this.$emit('change', this.form)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.group {
    border-radius: 0 4px 4px 0;
}

</style>