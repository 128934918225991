<template>
    <v-card v-if="client" @click="emitClick" class="text-center pa-1 hover-shadow" outlined>
        <v-avatar size="60" tile>
            <v-img v-if="clientImg" :src="`${baseUrl}${clientImg}`" />
            <v-icon v-else class="py-6" color="gold" size="60">mdi-warehouse</v-icon>
        </v-avatar>
        <div class="text-truncate mt-1">
            {{ client.name }}
        </div>
    </v-card>
</template>

<script>
export default {
    name: "ClientCardSquare",
    props: {
        client: {
            type: Object,
            default: null,
        },
    },
    computed: {
        baseUrl() {
            return process.env.VUE_APP_BASE_URL || null
        },
        clientImg(vm = this) {
            return vm.client?.filepath || null
        },
    },
    methods: {
        emitClick() {
            const vm = this
            vm.$emit('click')
        }
    }
}
</script>

<style scoped>

</style>