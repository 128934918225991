<template>
    <v-container>

        <v-row align="end" class="mt-5">
            <v-col cols="6">
                <div class="text-welcome">
                    Bienvenido
                    <div class="subtext-welcome">
                        {{ accountName }}
                    </div>
                </div>
            </v-col>
            <v-col cols="6">
                <kimsa-text-field-with-select
                    dense outlined flat solo
                    placeholder="Buscar"
                    :items="searchOptions"
                    append-icon="mdi-magnify"
                    @change="onChangeSearch($event)"
                    :loading="loadingSearch"
                />
            </v-col>
        </v-row>

        <!-- NEED CHANGE PASSWORD -->
        <v-row v-if="needChangePassword" class="my-5 mb-n10">
            <v-col cols="12">
                <v-alert type="info" color="accent" dense border="left"
                         @click.native="$router.push({name: 'change-password'})" style="cursor:pointer !important;">
                    Aún no actualiza su contraseña.
                </v-alert>
            </v-col>
        </v-row>

        <v-row class="mt-10">
            <!-- CLIENTS / MEETINGS SAME COLUMN -->
            <v-col cols="12" v-if="isClient">
                <budget-charts is-dashboard-view :budget="budgetData" :month="currentMonth"/>
            </v-col>
            <v-col cols="6" class="border-separation">
                <!-- CLIENTS -->
                <template v-if="!isClient">
                    <v-row align="center">
                        <v-col cols="6">
                            <v-icon color="black" size="30">mdi-account-outline</v-icon>
                            <div class="title-section">
                                Clientes
                            </div>
                        </v-col>
                        <v-col cols="6" align="right" v-if="clients.length > 0">
                            <v-btn outlined color="primary" tile class="normal-btn" @click="goToClients">
                                Ver todos
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense class="mt-3" v-if="clients.length > 0">
                        <v-col cols="3" v-for="client in clients" :key="client.id">
                            <client-card-square @click="goToClient(client.id)" :client="client"/>
                        </v-col>
                        <v-col cols="12" align="right">
                        <span class="order-by">
                            Ordenado por última interacción
                        </span>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" v-else>
                        <v-col cols="12">
                            <v-alert outlined type="info" dense color="accent">
                                Sin Clientes aún
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>

                <!-- MEETINGS -->
                <v-row>
                    <v-col cols="12">
                        <v-icon color="black" size="30">mdi-clipboard-text-outline</v-icon>
                        <div class="title-section">
                            Actas de Reunión
                        </div>
                    </v-col>
                </v-row>
                <v-row dense class="mt-3" v-if="meetings.length > 0">
                    <v-col cols="12" v-for="meeting in meetings" :key="meeting.id">
                        <meeting-card :meeting="meeting" @click="goToMeetingsTab(meeting)"/>
                    </v-col>
                    <v-col cols="6" class="mt-3">
                        <v-btn outlined color="primary" tile class="normal-btn" @click="goToMeetings">
                            Ver todos
                        </v-btn>
                    </v-col>
                    <v-col cols="6" align="right" class="mt-3">
                        <span class="order-by">
                            Ordenado por última interacción
                        </span>
                    </v-col>
                </v-row>
                <v-row class="mt-3" v-else>
                    <v-col cols="12">
                        <v-alert outlined type="info" dense color="accent">
                            Sin Actas de Reunión aún
                        </v-alert>
                    </v-col>
                </v-row>
            </v-col>
            <!-- TASKS COLUMN -->
            <v-col cols="6">
                <v-row align="center">
                    <v-col cols="6">
                        <v-icon color="black" size="30">mdi-pin-outline</v-icon>
                        <div class="title-section">
                            Tareas
                        </div>
                    </v-col>
                    <v-col cols="6" align="right">
                        <div v-for="status in statusOptions" :key="status.value" class="status-text">
                            <v-avatar size="13" :color="status.color"></v-avatar>
                            {{ status.name }}
                        </div>
                    </v-col>
                </v-row>
                <v-row dense class="mt-3" v-if="tasks.length > 0">
                    <v-col cols="12" v-for="task in tasks" :key="task.id">
                        <task-card :task="task" @click="showTaskDialog(task)"/>
                    </v-col>
                    <v-col cols="12" align="right" class="mt-3">
                        <v-btn outlined color="primary" tile class="normal-btn" @click="goToTasks">
                            Ver todos
                        </v-btn>
                    </v-col>
                    <detail-task-dialog ref="detail"/>
                </v-row>
                <v-row class="mt-3" v-else>
                    <v-col cols="12">
                        <v-alert outlined type="info" dense color="accent">
                            Sin Tareas asignadas.
                        </v-alert>
                        <v-col cols="12" align="right" class="mt-3">
                            <v-btn outlined color="primary" tile class="normal-btn" @click="goToTasks">
                                Ver todos
                            </v-btn>
                        </v-col>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

import {mapState} from "vuex";
import KimsaTextFieldWithSelect from "@/components/common/inputs/KimsaTextFieldWithSelect";
import Axios from "@/utils/Axios";
import ClientCardSquare from "@/components/client/ClientCardSquare";
import MeetingCard from "@/components/meeting/MeetingCard"
import TaskCard from "@/components/task/TaskCard";
import BudgetCharts from "@/components/budget/BudgetCharts";
import DetailTaskDialog from "@/components/task/DetailTaskDialog";

export default {
    components: {DetailTaskDialog, BudgetCharts, TaskCard, ClientCardSquare, KimsaTextFieldWithSelect, MeetingCard},
    data() {
        return {
            loadingSearch: false,
            search: '',
            searchOptions: [
                {name: 'Clientes', value: 'clients', icon: 'mdi-account-outline'},
                {name: 'Tareas', value: 'tasks', icon: 'mdi-pin-outline'},
                {name: 'Actas', value: 'meetings', icon: 'mdi-clipboard-text-outline'},
            ],
            statusOptions: [],
            clients: [],
            tasks: [],
            meetings: [],
            budgetData: null,
            currentMonth: 'january',
        }
    },
    async mounted() {
        const vm = this
        // console.log('vm',vm)
        await vm.loadStatus()
        await vm.loadDashboard()
        if (vm.isClient) {
            await vm.loadBudgets()
            vm.searchOptions = vm.searchOptions.filter(search => search.value !== 'clients')
        }
    },
    computed: {
        ...mapState(['account']),
        accountName() {
            return this.account && this.account.name || 'n/d'
        },
        needChangePassword() {
            return !!(this.account?.need_change_password)
        },
        clientId() {
            return this.account?.client_id || null
        },
    },
    methods: {
        async loadDashboard() {
            const vm = this
            vm.loadingSearch = true
            await Axios.post(`dashboard`, vm.search).then(res => {
                // console.log('res loadDashboard', res.data.result)
                vm.clients = res.data.result.clients
                vm.tasks = res.data.result.tasks
                vm.meetings = res.data.result.meetings
            }).catch(er => {
                console.log('error loadDashboard', er)
            })

            vm.loadingSearch = false
        },
        async loadBudgets() {
            const vm = this
            if (!vm.clientId) return

            vm.loading = true
            await Axios.post(`client/${vm.clientId}/budgets`).then(res => {
                console.log('res loadBudgets', res)
                vm.budgets = res.data.result.budgets.map(budget => {
                    return {
                        id: budget.id,
                        client: budget.client.name,
                        year: budget.year,
                        user: budget.user.name,
                        financialId: budget.financial_statement?.id || null,
                        financial: budget.financial_statement || null,
                        revenue: budget.revenue,
                        costSale: budget.cost_sale,
                        operatingExpenses: budget.operating_expenses,
                        otherExpenses: budget.other_expenses,
                    }
                })

                let currentYear = Number(vm.$moment().format('YYYY'))
                let currentBudget = vm.budgets.find(b => Number(b.year) === currentYear)
                if (!currentBudget) currentBudget = vm.budgets.find(b => Number(b.year) === currentYear - 1)
                vm.budgetData = currentBudget || null
                vm.currentMonth = vm.getLastMonth()
            }).catch(er => {
                console.log('error loadBudgets', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        getLastMonth() {
            const vm = this
            if (!vm.budgetData || !vm.budgetData.financial) return 'january'

            let months = ["january", "february", "march", "april", "may", "june", "july",
                "august", "september", "october", "november", "december"]

            let sumAccounts = {
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
            }

            vm.budgetData.financial?.revenue?.forEach(revenue => {
                months.forEach(month => {
                    sumAccounts[month] += Number(revenue[month] || 0)
                })
            })

            let currentMonth = 'january'
            months.forEach(month => {
                if (sumAccounts[month] > 0) {
                    currentMonth = month
                }
            })

            return currentMonth
        },
        async loadStatus() {
            const vm = this
            await Axios.get('status').then(res => {
                // console.log('res loadStatus', res.data.result)
                vm.statusOptions = res.data.result.status
            }).catch(er => {
                console.log('error loadStatus', er)
            })
        },
        onChangeSearch(search) {
            const vm = this
            vm.search = {
                search: search.model,
                mode: search.selected
            }
            if (search.model.length === 0 || search.model.length > 3) vm.loadDashboard()
        },
        goToClients() {
            this.$router.push({name: 'clients'})
        },
        goToMeetingsTab(meeting) {
            const vm = this
            vm.$router.push({name: 'clients.details', params: {id: meeting.client_id}, query: {'tab': 'Meetings'}})
        },
        goToMeetings() {
            const vm = this
            if (vm.isClient) {
                return vm.$router.push({
                    name: 'clients.details',
                    params: {id: vm.account.client_id},
                    query: {'tab': 'Meetings'}
                })
            }
            return vm.$router.push({name: 'meetings'})
        },
        showTaskDialog(task) {
            const vm = this
            vm.$refs.detail.show(task.id)
        },
        goToTasks() {
            const vm = this
            if (vm.isClient) {
                return vm.$router.push({
                    name: 'clients.details',
                    params: {id: vm.account.client_id},
                    query: {'tab': 'Tasks'}
                })
            }
            return vm.$router.push({name: 'tasks'})
        },
        goToClient(id) {
            const vm = this
            vm.$router.push({name: 'clients.details', params: {id}})
        }
    },
    watch: {
        clientId() {
            this.loadBudgets()
        }
    }
}
</script>

<style scoped>

.text-welcome {
    font-size: 20px;
    color: black;
}

.subtext-welcome {
    font-size: 25px;
    font-weight: bold;
    color: var(--v-primary-base);
    border-bottom: 3px solid var(--v-primary-base);
    width: fit-content;
}

.border-separation {
    border-right: 2px dashed rgba(51, 51, 51, .4);
}

.status-text {
    display: inline-block;
    font-size: 11px;
    margin-right: 5px;
}

.order-by {
    font-size: 13px;
    color: #a8a8a8;
}

</style>
